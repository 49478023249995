import { Icon } from '@iconify/react';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { PATH_DASHBOARD } from '../../../routes/paths';

const navConfig = [
  {
    items: [
      {
        title: 'Attributes',
        path: PATH_DASHBOARD.dorm.report,
        icon: <SettingsSuggestIcon />,
        children: [
          { title: 'Salary Range', path: PATH_DASHBOARD.attributes.network },
          { title: 'Language Level', path: PATH_DASHBOARD.attributes.storage },
          { title: 'Cities', path: PATH_DASHBOARD.attributes.brand },
          { title: 'Job Type', path: PATH_DASHBOARD.attributes.category },
          { title: 'Salary Frequency', path: PATH_DASHBOARD.attributes.model },
        ],
      },
      { title: 'Dashboard', path: '/dashboard/home', icon: <Icon icon="ri:dashboard-line" /> },
      { title: 'Job Management', path: PATH_DASHBOARD.dorm.job, icon: <Icon icon="ic:sharp-inventory" /> },
      { title: 'Blogs Posting', path: PATH_DASHBOARD.blog.posts, icon: <ArticleIcon /> },
    ],
  },
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/home', icon: <Icon icon="ri:dashboard-line" /> },
      { title: 'Job Management', path: PATH_DASHBOARD.dorm.job, icon: <Icon icon="ic:sharp-inventory" /> },],
  },
];

export default navConfig;
