import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    salaryRanges: [],
};

const salaryRangeSlice = createSlice({
    name: 'salaryRange',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getSalaryRangeSuccess(state, action) {
            state.isLoading = false;
            state.salaryRanges = action.payload;
        },
    },
});

export default salaryRangeSlice.reducer;

export const { startLoading, hasError, getSalaryRangeSuccess } = salaryRangeSlice.actions;

export const getAllSalaryRanges = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('attributes/salaryRange/get-all-salaryRange');
        dispatch(getSalaryRangeSuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};

export const addSalaryRange = (salaryRange) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const currentUser = JSON.parse(localStorage.getItem('currentuser'));
        const response = await axios.post('attributes/salaryRange/add-salaryRange', {
            SalaryRange: salaryRange,
            AddedBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
        })
        dispatch(getAllSalaryRanges());
        return response;
    } catch (error) {
        dispatch(hasError(error));
        return error;
    }
};

export const deleteSalaryRange = (id) => async (dispatch) => {
    dispatch(startLoading());
    try {
        await axios.delete(`attributes/salaryRange/salaryRange-delete/${id}`);
        dispatch(getAllSalaryRanges());
    } catch (error) {
        dispatch(hasError(error));
    }
};

export const updateSalaryRange = (salaryRange, id) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.put(`attributes/salaryRange/salaryRange-update/${id}`, {
            SalaryRange: salaryRange
        });
        dispatch(getAllSalaryRanges());
        return response;
    } catch (error) {
        dispatch(hasError(error));
    }
};
