import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    orderItems: [],
};

const orderManagementSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getOrderSuccess(state, action) {
            state.isLoading = false;
            state.orderItems = action.payload;
        },
    },
});

export default orderManagementSlice.reducer;

export const { startLoading, hasError, getOrderSuccess } = orderManagementSlice.actions;

export const getAllOrders = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('shipments/');
        dispatch(getOrderSuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};
