import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Avatar, Typography, ListItemText, ListItemAvatar, MenuItem, FormControlLabel, Switch } from '@mui/material';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
// utils
import { useSelector } from 'react-redux';
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import BadgeStatus from '../../../components/BadgeStatus';
import { IconButtonAnimate } from '../../../components/animate';
import { PRIMARY } from '../../../theme/palette';
import { dispatch } from '../../../redux/store';
import { toggleDropdownItem } from '../../../redux/slices/settings';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 50;

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const dropdownAccess = useSelector((state) => state.dropdown.dropdownItems);
  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 50,
          height: 50,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <SettingsSuggestIcon width={40} height={40} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 250,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <Typography variant="h4" color={PRIMARY.darker} sx={{ p: 1.5, fontWeight: 900 }}>
          Settings
        </Typography>

        <Scrollbar sx={{ height: ITEM_HEIGHT * 5 }}>
          {dropdownAccess?.map((dropdown, index) => (
            <MenuItem key={index} onClick={() => dispatch(toggleDropdownItem(dropdown._id))} >
              <ListItemText primary={dropdown.DropdownName} />
              <FormControlLabel control={<Switch size="small" checked={dropdown.Active} />} label="" />
            </MenuItem>
          ))}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
