import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    hospitalCheckedReports: [],
};

const hospitalAllCheckedReportsSlice = createSlice({
    name: 'hospitalAllCheckedReports',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getHospitalAllCheckedReportsSuccess(state, action) {
            state.isLoading = false;
            state.hospitalCheckedReports = action.payload;
        },
    },
});

export default hospitalAllCheckedReportsSlice.reducer;

export const { startLoading, hasError, getHospitalAllCheckedReportsSuccess } = hospitalAllCheckedReportsSlice.actions;

export const getHospitalAllCheckedReports = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('hospital/get/checked/reports');
        dispatch(getHospitalAllCheckedReportsSuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};