import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    categoryItems: [],
};

const categorySlice = createSlice({
    name: 'Category',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getCategorySuccess(state, action) {
            state.isLoading = false;
            state.categoryItems = action.payload;
        },
    },
});

export default categorySlice.reducer;

export const { startLoading, hasError, getCategorySuccess } = categorySlice.actions;

export const getAllCategorys = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('attributes/jobType/get-all-jobType');
        dispatch(getCategorySuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};

export const addCategory = (JobType) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const currentUser = JSON.parse(localStorage.getItem('currentuser'));
        const response = await axios.post('attributes/jobType/add-jobType', {
            JobType,
            AddedBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
        })
        dispatch(getAllCategorys());
        return response;
    } catch (error) {
        dispatch(hasError(error));
        return error;
    }
};

export const deleteCategory = (id) => async (dispatch) => {
    dispatch(startLoading());
    try {
        await axios.delete(`attributes/jobType/delete-jobType/${id}`);
        dispatch(getAllCategorys());
    } catch (error) {
        dispatch(hasError(error));
    }
};