import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    brandItems: [],
};

const brandSlice = createSlice({
    name: 'Brand',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getBrandSuccess(state, action) {
            state.isLoading = false;
            state.brandItems = action.payload;
        },
    },
});

export default brandSlice.reducer;

export const { startLoading, hasError, getBrandSuccess } = brandSlice.actions;

export const getAllBrands = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('attributes/city/get-all-city');
        dispatch(getBrandSuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};

export const addBrand = (City) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const currentUser = JSON.parse(localStorage.getItem('currentuser'));
        const response = await axios.post('attributes/city/add-city', {
            City,
            AddedBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
        })
        dispatch(getAllBrands());
        return response;
    } catch (error) {
        dispatch(hasError(error));
        return error;
    }
};

export const deleteBrand = (id) => async (dispatch) => {
    dispatch(startLoading());
    try {
        await axios.delete(`attributes/city/delete-city/${id}`);
        dispatch(getAllBrands());
    } catch (error) {
        dispatch(hasError(error));
    }
};
