import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    dropdownItems: [],
};

const dropdownSlice = createSlice({
    name: 'Dropdown',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getDropdownSuccess(state, action) {
            state.isLoading = false;
            state.dropdownItems = action.payload;
        },

    },
});

export default dropdownSlice.reducer;

export const { startLoading, hasError, getDropdownSuccess, toggleDropdownSuccess } = dropdownSlice.actions;

export const getAllDropdownItems = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('settings/dropdown/get-all-dropdown');
        dispatch(getDropdownSuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};

export const toggleDropdownItem = (id) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.put(`settings/dropdown/${id}`);
        dispatch(getAllDropdownItems());

        return response;
    } catch (error) {
        dispatch(hasError(error));
        return error;
    }
};
