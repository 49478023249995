import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    storageItems: [],
};

const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getStorageSuccess(state, action) {
            state.isLoading = false;
            state.storageItems = action.payload;
        },
    },
});

export default storageSlice.reducer;

export const { startLoading, hasError, getStorageSuccess } = storageSlice.actions;

export const getAllStorages = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('attributes/languageLevel/get-all-languageLevel');
        dispatch(getStorageSuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};

export const addStorage = (LanguageLevel) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const currentUser = JSON.parse(localStorage.getItem('currentuser'));
        const response = await axios.post('attributes/languageLevel/add-languageLevel', {
            LanguageLevel,
            AddedBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
        })
        dispatch(getAllStorages());
        return response;
    } catch (error) {
        dispatch(hasError(error));
        return error;
    }
};

export const deleteStorage = (id) => async (dispatch) => {
    dispatch(startLoading());
    try {
        await axios.delete(`attributes/languageLevel/delete-languageLevel/${id}`);
        dispatch(getAllStorages());
    } catch (error) {
        dispatch(hasError(error));
    }
};
