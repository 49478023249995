import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    userItems: [],
};

const userManagementSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getUserSuccess(state, action) {
            state.isLoading = false;
            state.userItems = action.payload;
        },
    },
});

export default userManagementSlice.reducer;

export const { startLoading, hasError, getUserSuccess } = userManagementSlice.actions;

export const getAllUsers = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('users/get-all-users');
        dispatch(getUserSuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};
