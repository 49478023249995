import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    modelItems: [],
};

const modelSlice = createSlice({
    name: 'Model',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getModelSuccess(state, action) {
            state.isLoading = false;
            state.modelItems = action.payload;
        },
    },
});

export default modelSlice.reducer;

export const { startLoading, hasError, getModelSuccess } = modelSlice.actions;

export const getAllModels = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('attributes/salaryFrequency/get-all-salaryFrequency');
        dispatch(getModelSuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};

export const addModel = (SalaryFrequency) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const currentUser = JSON.parse(localStorage.getItem('currentuser'));
        const response = await axios.post('attributes/salaryFrequency/add-salaryFrequency', {
            SalaryFrequency,
            AddedBy: `${currentUser?.firstName} ${currentUser?.lastName}`
        })
        dispatch(getAllModels());
        return response;
    } catch (error) {
        dispatch(hasError(error));
        return error;
    }
};

export const deleteModel = (id) => async (dispatch) => {
    dispatch(startLoading());
    try {
        await axios.delete(`attributes/salaryFrequency/delete-salaryFrequency/${id}`);
        dispatch(getAllModels());
    } catch (error) {
        dispatch(hasError(error));
    }
};