import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    inventoryItems: [],
};

const inventoryManagementSlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getInventorySuccess(state, action) {
            state.isLoading = false;
            state.inventoryItems = action.payload;
        },
    },
});

export default inventoryManagementSlice.reducer;

export const { startLoading, hasError, getInventorySuccess } = inventoryManagementSlice.actions;

export const getAllInventory = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('job/get-all-jobs');
        dispatch(getInventorySuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};
